// AppThemeProvider.js
import React, { createContext, useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./theme"; // Your light and dark theme imports

// Create context to manage theme mode
export const ColorModeContext = createContext();

const AppThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("light"); // Default theme is light

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ColorModeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
        <CssBaseline /> {/* Ensure consistent baseline styling */}
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AppThemeProvider;
