import React from "react";
import Slider from "../components/Slider";
import { Box, Typography, Grid } from "@mui/material";

const Homepage = () => {
  return (
    <Box sx={{ m: 0, p: 0 }}>
      {/* Slider Component */}
      <Slider />

      {/* Overlapping Boxes */}
      <Grid
        container
        spacing={6} // Increased spacing for better separation
        sx={{
          position: "absolute",
          top: "75%", // Adjust for overlap with the slider
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          zIndex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {[
          // Card Data
          { title: "New Batch Start", image: "/images/coomingsoon.png" },
          { title: "Our Teachers", image: "/images/teachers.jpg" },
          { title: "Programs", image: "/images/coaching1.jpg" },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "400px",
                width: "100%",
                borderRadius: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start", // Align content at the top
                alignItems: "center",
                overflow: "hidden",
                color: "white",
                textShadow: "1px 1px 5px rgba(0, 0, 0, 0.7)",
                padding: "16px", // Add padding for the title
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  padding: "8px 16px",
                  borderRadius: "10px",
                  marginBottom: "16px", // Add spacing between title and content
                }}
              >
                {item.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Homepage;
