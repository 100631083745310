import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";

// Image data for the slider
const images = [
  { src: "/images/g1.jpg", label: "Learn More About Our Courses" },
  { src: "/images/g2.jpg", label: "Join the Best Teachers in Town" },
  { src: "/images/g3.jpg", label: "Achieve Your Dreams with Us" },
  { src: "/images/g4.jpg", label: "Explore New Programs Today" },
  { src: "/images/gicd1.jpg", label: "Explore New Programs Today" },
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Switch to the next image every 8 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw", // Full viewport width
        height: "calc(100vh - 64px)", // Full viewport height minus any navbar height (64px as an example)
        margin: 0, // No margins
        padding: 0, // No paddings
        overflow: "hidden", // Hide overflow content
        top: 0,
        left: 0,
        boxSizing: "border-box", // Prevent box model issues
      }}
    >
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `url(${image.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%", // Fill parent height
            width: "100%", // Fill parent width
            position: "absolute",
            top: 0,
            left: 0,
            transition: "opacity 1s ease-in-out",
            opacity: index === currentIndex ? 1 : 0,
            zIndex: index === currentIndex ? 1 : 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "50%",
              left: "80%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              color: "#fff",
              padding: "1rem",
              borderRadius: "5px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 2 }}
              onClick={() => alert("Redirecting to Enquiry Form")}
            >
              Enquiry Form
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Slider;
