import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const About = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={4} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/IMG_1145.JPG" // Replace with your photo path
            alt="About Us"
            sx={{ width: "80%", borderRadius: "16px", boxShadow: 3 }}
          />
        </Grid>
        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to GICD! We are committed to providing the best educational
            resources and training programs to help you achieve your goals.
          </Typography>
          <Typography variant="body1" paragraph>
            Our team of experienced educators and innovative programs are
            designed to foster growth and success.
          </Typography>
          <Typography variant="body1" paragraph>
            Join us today and take the first step toward a brighter future.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
