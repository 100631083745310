// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Homepage from "./components/Homepage";
import About from "./components/About";
import AppThemeProvider from "./AppThemeProvider"; // Import AppThemeProvider

const App = () => {
  return (
    <AppThemeProvider>
      {" "}
      {/* Wrap the app with the theme provider */}
      <Router>
        <Routes>
          {/* Homepage Route */}
          <Route
            path="/"
            element={
              <Layout>
                <Homepage />
              </Layout>
            }
          />

          {/* About Page Route */}
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </AppThemeProvider>
  );
};

export default App;
