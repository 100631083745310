import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorModeContext } from "../AppThemeProvider"; // Updated context import
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTheme } from "@mui/material/styles";

const Layout = ({ children }) => {
  const { toggleColorMode } = useContext(ColorModeContext); // Access toggleColorMode from context
  const theme = useTheme(); // Get current theme mode

  return (
    <Box sx={{ margin: 0, padding: 0, width: "100vw", height: "100vh" }}>
      {/* Topbar */}
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: "space-between", px: 2 }}>
          {/* Logo and Title */}
          <Box display="flex" alignItems="center">
            <Box
              component="img"
              src="/images/gicd.jpg"
              alt="Logo"
              sx={{
                height: 40,
                width: 40,
                marginRight: 1,
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.2)" },
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              GICD
            </Typography>
          </Box>

          {/* Navigation Tabs */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              marginLeft: "10%",
            }}
          >
            {[
              { label: "Home", path: "/" },
              { label: "About", path: "/about" },
              { label: "Teachers", path: "/#" },
              { label: "Course", path: "/#" },
              { label: "Program", path: "/#" },
              { label: "Blog", path: "/#" },
              { label: "Contact Us", path: "/contact" },
            ].map((tab) => (
              <Button
                key={tab.label}
                color="inherit"
                component={Link}
                to={tab.path} // Use the `to` prop for navigation
                sx={{
                  minWidth: "auto",
                  padding: "6px 12px",
                  fontSize: "0.875rem",
                  fontWeight: "medium",
                }}
              >
                {tab.label}
              </Button>
            ))}
          </Box>

          {/* Theme Toggle */}
          <IconButton onClick={toggleColorMode} color="inherit" sx={{ mr: 2 }}>
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>

          {/* Social Media Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box display="flex" gap={1}>
              <IconButton
                href="https://facebook.com"
                color="inherit"
                target="_blank"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                href="https://twitter.com"
                color="inherit"
                target="_blank"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                href="https://instagram.com"
                color="inherit"
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://wa.me/1234567890"
                color="inherit"
                target="_blank"
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                href="https://linkedin.com"
                color="inherit"
                target="_blank"
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                marginTop: 0.5,
                whiteSpace: "nowrap",
              }}
            >
              91992208
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Page Content */}
      <Box
        sx={{
          mt: 0,
          p: 0,
          overflow: "hidden",
          minHeight: "calc(100vh - 64px)", // Adjust for AppBar height
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
